import React from 'react';

function Header() {
		return (
			<div>
				<h1 className='text-center display-1'>Gourmet Survey</h1>
				<h3 className='text-center'>Demo database resets hourly.</h3>
			</div>
		);
	}
	
	export default Header;
