import React from 'react'

const NotFound = () => {
	return (
		<div>
			<h2>404</h2>
			Page not found.
		</div>
	);
}

export default NotFound